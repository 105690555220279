import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.css']
})
export class AddBankComponent implements OnInit {
  addAccountForm: FormGroup;
  bankList: any=[];

  constructor(public router: Router,public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
    this.getBanks()
  }
// Form Validation 
formValidation(){
  this.addAccountForm = new FormGroup({
    'accountHolderName': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
    'accountNumber': new FormControl('',[Validators.required]),
    'contactNumber': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/i)]),
    'ifscCode': new FormControl('',[Validators.required]),
    'ibanNumber':new FormControl('',[Validators.required]),
    'bankName':new FormControl('',[Validators.required]),
    'swiftNo':new FormControl('',[Validators.required]),
  })
}
// Contact Us Functionality
submitFunc(){
  let data={
      "accountHolderName": this.addAccountForm.value.accountHolderName,
      "accountNo": this.addAccountForm.value.accountNumber,
      "bankName": this.addAccountForm.value.bankName,
      "contactNo": this.addAccountForm.value.contactNumber,
      "ibanNo":  this.addAccountForm.value.ibanNumber,
      "ifsc": this.addAccountForm.value.ifscCode,
      "imageUrl": "",
      "isActive": true,
      "swiftNo": this.addAccountForm.value.swiftNo
  }
//this.service.showSpinner();
this.service.postCandyPixelForms('account/add-user-bank-account',data).subscribe(res=>{
  
  if (res['status']==200){
    this.service.showSuccessMessage('Bank added successfully')
    this.addAccountForm.reset();
    //this.service.hideSpinner();
    this.getBanks()
  }
}, err=>{
  //this.service.hideSpinner();
})
}

getBanks(){
  this.service.showSpinner();
  this.service.getCandyPixelForms('account/get-user-account-list?page=0&pageSize=10',).subscribe(res=>{
    this.service.hideSpinner();
    if (res['status']==200){
      this.bankList= res['data']
      console.log("dddfdfgdfgdg",this.bankList)
      // this.service.showSuccessMessage('Bank  successfully')
      // this.addAccountForm.reset();
      this.service.hideSpinner();
    }
  }, err=>{
    this.service.hideSpinner();
  })
}
}
