import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css']
})
export class PaymentStatusComponent implements OnInit {
  action: any = [];
  responseTxt: any;
  responseCode: string;
  paymentData: any;
  id:any
  status: any;
  constructor(public service: MainService, public router: Router) { 
    console.log("hello world")
  }

  ngOnInit() {
   
    this.id = localStorage.getItem('OrderId')
    this.service.showSpinner();
    setTimeout(() => {
      this.service.hideSpinner();
      this.PaymentStatus()
    }, 3000);
  }

  PaymentStatus() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/checkout/v1/get-payment-status?orderId=' +this.id).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.paymentData=res['data']
        this.status =  this.paymentData.txStatus
        //this.paymentLink= res['data']['paymentLink']
       
        this.service.showSuccessMessage('Success')
       // window.open(this.paymentLink)
       // this.history()
        // this.otcBuyForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }



  // Approve Withdraw 
  approveWithdraw(url) {
    var apireq = {
      "isWithdraw": true,
      "withdrawToken": url.slice(5)
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/wallet/approve-withdraw', apireq).subscribe(res => {
      this.service.hideSpinner();
      this.responseTxt = res['message'];
      if (res['status'] == 200) {
        this.responseCode = '1'
        this.service.showSuccessMessage(res['message'])
      } else {
        this.service.showErrorMessage(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      }
    })
  }

 

}
