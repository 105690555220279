import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  constructor(public router: Router,public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.forgotForm = new FormGroup({
      'email': new FormControl('',[Validators.required,Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)])
    })
  }
 // Forgot Functionality
 forgotFunc() {
  var url = "account/forget-password?email="+this.forgotForm.value.email+"&webUrl=" + this.service.webUrl + "/reset-password/";

   this.service.showSpinner();
   this.service.getCandyPixelForms(url).subscribe(res => {
     console.log(res)
     if (res['status'] == 200) {
       this.forgotForm.reset();
       this.service.showSuccessMessage(res['message'])
       this.service.hideSpinner();
     }
     if(res['error'].status == 400){
      this.service.showErrorMessage('')
     }
     else {
       this.service.hideSpinner();
       this.service.showErrorMessage(res['message']);
     }
   }, err => {
     this.service.hideSpinner();
   })
 }



  navigateToBy(path) {
    this.router.navigateByUrl(path);
  }
}
