import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  isLoggedIn:any;
  faqData: any;
  constructor(public service : MainService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('authToken') ? localStorage.getItem('authToken') : ''
  }

  getCarrierData(){ 
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=FAQ').subscribe(res=>{
           if (res['status']==200){
             this.faqData=res['data'].pageData;
           }
           if (res['status']==201){
            this.service.showErrorMessage('No Data Found')
          }
    })
   }

}
