import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  isLoggedIn: any
  aboutData: any;
  noData: any='false';
  constructor(public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('credential') ? localStorage.getItem('credential') : ''
    console.log("is logged?::", this.isLoggedIn)
    this.getAboutUs()
  }

  getAboutUs(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=ABOUT%20US').subscribe(res=>{
           if (res['status']==200){
             this.aboutData=res['data'].pageData;
             this.service.hideSpinner()
             this.service.showSuccessMessage(res['message'])
           }
           if (res['status']==201){
            this.service.hideSpinner()
            this.service.showErrorMessage('No Data Found')
          }
    })
   }

 
}
