import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ToastrService } from 'ngx-toastr'

declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  qrCode: any = '';
  secretKey: any = '';
  googleAuth: any = '';
  twoFAStatus: any;
  smsAuth: any = '';
  obj: any = {}
  toastrService: any;
  showEye: boolean = false;
  siteKey: string;
  remember: boolean = false;
  recaptcha: any = "";
  ipAddress: string;

  constructor(public router: Router, public service: MainService, private toaster: ToastrService, private fb: FormBuilder) {
    this.siteKey = '6LdqEsgZAAAAAFPfet02RuvSEUgU0F2yM2m54DVZ'; // for recaptcha local
    // this.siteKey ='6LfmltoZAAAAAP3g1zfSN5f_P99GHdNaKxGBnU5t'; // created by  for doamin
  }

  ngOnInit() {

    window.scrollTo(0, 0)
    this.formValidation();
    this.getIP();
  }

  // google recaptcha 
  resolved(e) {
    if (e)
      this.recaptcha = e;
    else {
      this.recaptcha = "";
    }
  }

  /** to show and hide password */
  showHidePassword() {
    if (this.showEye == false) {
      this.showEye = true;
    } else if (this.showEye == true) {
      this.showEye = false;
    }
  }

  handleFileInput(event) {
    if (event.target.files && event.target.files[0]) {
      if (true) {
        var reader = new FileReader()
        reader.onload = (e) => {
          this.obj.myfile = event.target.files[0];
        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
  }

  /** Function to restrict space */
  restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  // Form Validation 
  formValidation() {
    this.loginForm = this.fb.group({
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'password': new FormControl('', [Validators.required]),
      'remember': [true,]
    })
    if (JSON.parse(localStorage.getItem('remembers')) == true) {
      this.loginForm.patchValue({
        "email": window.atob(localStorage.getItem('email')),
        "password": window.atob(localStorage.getItem('password'))
      })
    } else {
      localStorage.clear();
      this.loginForm.reset();
    }
  }
  // Login Functionality
  loginFunc() {
    if (localStorage != null) {
      localStorage.setItem('email', window.btoa(this.loginForm.value.email))
      localStorage.setItem('password', window.btoa(this.loginForm.value.password))
    } else {
      this.loginForm.reset();
    }
    localStorage.setItem('remembers', JSON.stringify(this.loginForm.value.remember))
    this.service.showSpinner();
    var apireq = {
      'email': this.loginForm.value.email,
      'password': this.loginForm.value.password,
      "userAgent": "WEB",
      "location": "",
      "ipAddress": this.ipAddress
    }
    this.service.postCandyPixelForms('auth', apireq).subscribe(res => {
      //console.log(res)
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.toaster.success("Login Successfully ")

        this.twoFAStatus = res['data']['TwoFa'];
        if (res['data']['TwoFa'] == 'NONE') {
          localStorage.setItem('authToken', res['data']['token']);
          $('#suggest').modal({ backdrop: 'static' });
        }
        else if (res['data']['TwoFa'] == 'GOOGLE') {
          localStorage.setItem('authToken', res['data']['token']);
          $('#googleAuth').modal({ backdrop: 'static' });
        } else if (res['data']['TwoFa'] == 'SMS') {
          localStorage.setItem('authToken', res['data']['token']);
          $('#smsAuth').modal({ backdrop: 'static' });
          this.suggesstionFunc('sms')
        } else if (res['data']['TwoFa'] == 'SKIP') {
          this.service.changeLoginSub('login');
          localStorage.setItem('credential', res['data']['token']);
          this.router.navigate(['']);
        }
        this.service.initSocketNotification();
        this.service.initSocketChat(); //chat
      }
    }, err => {
      //   this.service.hideSpinner();
      //   if (err['error']['status'] == '203') {
      //     this.toaster.error(err['error']['message']);
      //     this.resSendEmail()
      //   }
      //  else if (err['error']['status'] == '401') {
      //   this.toaster.error(err['error']['message']);
      // }
      //   else {
      //     this.toaster.error("Please Enter Correct Credentials");
      //   }
      this.service.hideSpinner();
      console.log(err);

      if (err.error.status == 203) {
        this.service.showErrorMessage(err['error']['message']);
        this.resSendEmail()
      }
      else if (err['status'] == '401') {
        this.service.showErrorMessage('Please Enter Correct Credentials');
      }
      else {
        this.service.showErrorMessage("Please Enter Correct Credentials");
      }
    })
  }

  // Resend Email For Verification
  resSendEmail() {
    var url = "account/resend-verify-email?email=" + this.loginForm.value.email + "&webUrl=" + this.service.webUrl;
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
    }, err => {
      this.service.hideSpinner();
    })
  }
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
    if (action == 'Skip') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/skip-twoFa').subscribe(res => {
        if (res['status'] == 200) {
          this.toaster.success('Logged In');
          this.service.hideSpinner();
          $('#suggest').modal('hide');
          this.service.changeLoginSub('login');
          let credential = localStorage.getItem('authToken')
          localStorage.removeItem('authToken')
          localStorage.setItem('credential', credential);
          this.router.navigate(['']);
        }
        else {
          this.toaster.error(res['message'])
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else if (action == 'Google') {
      if (this.twoFAStatus == 'GOOGLE') {
        $('#suggest').modal('hide');
        $('#googleAuth').modal('show');
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms('account/google-auth').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.qrCode = res['data']['qrCode'];
            this.secretKey = res['data']['secretKey'];
            $('#suggest').modal('hide');
            $('#googleAuth').modal('show');
          }
        }, err => {
          this.service.hideSpinner();
        })
      }
    }
    else if (action == 'sms') {
      if (this.twoFAStatus == 'SMS') {
        this.service.showSpinner();
        this.service.getCandyPixelForms('auth/send-sms-code').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            $('#suggest').modal('hide');
            $('#smsAuth').modal('show');
            this.toaster.success(res['message']);
          }
          else {
            this.toaster.error(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '500') {
            this.toaster.error('Mobile number is not verified');
          }
          else {
            this.toaster.error(err['message']);
          }
        })
      } else {
        this.service.showSpinner();
        this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            $('#suggest').modal('hide');
            $('#smsAuth').modal('show');
            this.toaster.success(res['message']);
          }
          else {
            this.toaster.error(res['message']);
          }
        }, err => {
          this.service.hideSpinner();
          if (err['status'] == '500') {
            this.toaster.error('Mobile number is not verified');
          }
          else {
            this.toaster.error(err['message']);
          }
        })
      }
    }
  }

  // Verify Google / SMS Auth Functionality By Accounts
  verifyGoogleAuth(select) {
    if (select == 'Google') {
      var apireq = {};
      var url = '';
      if (this.twoFAStatus == 'GOOGLE') {
        url = 'auth/verify-google';
        apireq = {
          "otp": this.googleAuth,
          //"secretKey": this.secretKey
        }
      } else {
        url = 'account/verify-google-code';
        apireq = {
          "code": Number(this.googleAuth),
          "secretKey": this.secretKey,
          "ipAddress": this.ipAddress,
          "source": "WEB"
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'GOOGLE') {
            this.service.changeLoginSub('login');
            localStorage.setItem('credential', res['data']);
            sessionStorage.setItem('secretKey', this.secretKey);
          }
          this.toaster.success('Google Authentication Verified');
          $('#googleAuth').modal('hide');
          this.router.navigate(['']);
        }
        else {
          this.toaster.error('Google Authentication Failed');
        }
      }, err => {
        this.service.hideSpinner();
        this.toaster.error('Something Went Wrong');
      })
    }
    else if (select == 'sms') {
      var smsapireq = {};
      var url = '';
      if (this.twoFAStatus == 'SMS') {
        url = 'auth/verify-sms';
        smsapireq = {
          'code': this.smsAuth
        }
      }
      else {
        url = 'account/verify-sms-code';
        smsapireq = {
          'code': this.smsAuth,
          'ipAddress': this.ipAddress,
          'source': 'WEB'
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          $('#smsAuth').modal('hide');
          this.service.changeLoginSub('login');
          //localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'SMS')
            localStorage.setItem('credential', res['data']);
          this.toaster.success(res['message']);
          this.router.navigate(['']);
        }
        else {
          this.toaster.error(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        this.toaster.error('Something Went Wrong');
      })
    }
  }

  // to remove token
  removeToken() {
    localStorage.removeItem('authToken')
  }

  forgotpassword() {
    this.router.navigateByUrl('forgotpassword')
  }
  signup() {
    this.router.navigateByUrl('register')
  }

  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      //console.log("ipppp", this.ipAddress)
    });
  }

  navigateForget() {
    this.router.navigateByUrl('/forget-password')
  }
}
