import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.css']
})
export class TutorialsComponent implements OnInit {
  isLoggedIn:any;
  constructor(private service:MainService) { }
  carrierData:any
  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('credential') ? localStorage.getItem('credential') : ''
    this.getCarrier()
  }
  getCarrier(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=Tutorial').subscribe(res=>{
           if (res['status']==200){
             this.carrierData=res['data'].pageData;
             this.service.hideSpinner()
             this.service.showSuccessMessage(res['message'])
           }
           if (res['status']==201){
             this.service.hideSpinner()
            this.service.showErrorMessage('No Data Found')
          }
          else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
          }
    })
   }
}
