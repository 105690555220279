import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SignupDetailsComponent } from './components/signup-details/signup-details.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SupportComponent } from './components/support/support.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FaqComponent } from './components/faq/faq.component';
import { BuySellComponent } from './components/buy-sell/buy-sell.component';
import { MarketComponent } from './components/market/market.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { DepositHistoryComponent } from './components/deposit-history/deposit-history.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AddBankComponent } from './components/add-bank/add-bank.component';
import { MyBankComponent } from './components/my-bank/my-bank.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { WithdrawHistoryComponent } from './components/withdraw-history/withdraw-history.component';
import { AccountActivityComponent } from './components/account-activity/account-activity.component';

import { DisabledSmsAuthenticatorComponent } from './components/disabled-sms-authenticator/disabled-sms-authenticator.component';
import { EnabledSmsAuthenticatorComponent } from './components/enabled-sms-authenticator/enabled-sms-authenticator.component';
import { GoogleAuthenicationStep1Component } from './components/google-authenication-step1/google-authenication-step1.component';
import { GoogleAuthenicationStep2Component } from './components/google-authenication-step2/google-authenication-step2.component';
import { GoogleAuthenicationStep3Component } from './components/google-authenication-step3/google-authenication-step3.component';
import { GoogleAuthenicationStep4Component } from './components/google-authenication-step4/google-authenication-step4.component';
import { AdvanceExchangeComponent } from './components/advance-exchange/advance-exchange.component';
import { CarrierComponent } from './components/carrier/carrier.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
import { WalletTransactionStatusComponent } from './components/wallet-transaction-status/wallet-transaction-status.component';
import { OtcComponent } from './components/otc/otc.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { StaticDesclaimerComponent } from './component/static-desclaimer/static-desclaimer.component';



const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signup-details', component: SignupDetailsComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'advance-exchange', component: AdvanceExchangeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'support', component: SupportComponent },
  { path: 'carrier', component: CarrierComponent },
  { path: 'how-it-works', component: HowItWorksComponent },
  { path: 'tutorials', component: TutorialsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'buy-sell', component: BuySellComponent },
  { path: 'market', component: MarketComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'verify-email', component: VerifyEmailComponent },
  { path: 'deposit-history', component: DepositHistoryComponent },
  { path: 'wallet', component: WalletComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'add-bank', component: AddBankComponent },
  { path: 'my-bank', component: MyBankComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'withdraw-history', component: WithdrawHistoryComponent },
  { path: 'account-activity', component: AccountActivityComponent },

  { path: 'disabled-sms-authenticator', component: DisabledSmsAuthenticatorComponent },
  { path: 'enabled-sms-authenticator', component: EnabledSmsAuthenticatorComponent },
  { path: 'google-authentication-step1', component: GoogleAuthenicationStep1Component },
  { path: 'google-authentication-step2', component: GoogleAuthenicationStep2Component },
  { path: 'google-authentication-step3', component: GoogleAuthenicationStep3Component },
  { path: 'google-authentication-step4', component: GoogleAuthenicationStep4Component },
  { path: 'wallet-transaction-status/:id', component: WalletTransactionStatusComponent },
  { path: 'otc', component:OtcComponent},
  { path: 'payment-status', component: PaymentStatusComponent},
  { path: 'Disclaimer', component: StaticDesclaimerComponent},






  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
