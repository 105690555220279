import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
declare var $:any

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  profileData: any = {};
  arr: any = [];
  imageUrl2: any ="assets/images/mark-herrera.png"
  fileData: any;
  qrCode: any;
  secretKey: any;
  googleAuth: any = '';
  smsAuth: any = '';
  ipAddress: string;
  resultArr: any = [];
  kycStatus: boolean = false;
  constructor(public router: Router, public service: MainService,) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.formValidation()
    this.getProfile()
    window.scrollTo(0, 0)
    //this.getProfile();
    this.getIP() // get IP Address
    this.checkStatus()
    this.validateUserDetailsForm()
  }
  
  // Form Validation Functionality
  formValidation() {
    this.profileForm = new FormGroup({
      'firstName': new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i), Validators.required]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/i)]),
      'email': new FormControl('', Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)),
      'mobile': new FormControl('', Validators.pattern(/^[0-9]*$/i)),  
      'address': new FormControl('',[Validators.required]),
      "zipcode": new FormControl('',[Validators.required]),  
      'uniqueId': new FormControl('',[Validators.required]),
    })
  }

 // Get Profile Name-Email
 getProfile() {
  this.service.showSpinner();
  this.service.getCandyPixelForms('account/my-account').subscribe(res => {
    
    if (res['status'] == 200) {
      this.profileData = res['data'];
      let index = this.arr.findIndex(x => x.country == this.profileData.country)
      // this.stateList = this.arr[index].states;
      // this.mobileData = this.profileData.phoneNo.split('-');
      this.service.name = this.profileData.firstName
      setTimeout(()=>{
        this.profileForm.patchValue({
          'firstName': this.profileData.firstName,
          'lastName': this.profileData.lastName,
          'email': this.profileData.email,
          'mobile': this.profileData.phoneNo,
          'address': this.profileData.address,
          "zipcode": this.profileData.zipCode,
          "uniqueId":this.profileData.randomId
          
        });
        this.UserDetailsForm.patchValue({
          "fname": this.profileData.firstName,       
          "phone": this.profileData.phoneNo,        
          "gender": this.profileData.gender,
          "country": this.profileData.country,   
          "lname": this.profileData.lastName,    
          "email_add": this.profileData.email,
          "address": this.profileData.address,
          "city": this.profileData.city,
        });
        this.service.hideSpinner();
      },1000)
      
      // if(this.profileData.country) {
      //   this.getStateList(this.profileData.country);
      // }
      if (this.profileData.imageUrl) {
        this.imageUrl2 = this.profileData.imageUrl != null ? this.profileData.imageUrl : 'assets/images/mark-herrera.png';
        this.service.img = this.imageUrl2
      }
      //this.states =this.profileData.state
    }
    else {
      this.service.hideSpinner();
      this.service.showErrorMessage(res['message']);
    }
  }, err => {
    this.service.hideSpinner();
    this.service.showErrorMessage(err['message']);
  })
}

// Image Functionality Start Here
uploadImg(event){
  var self = this;
      if(event.target.files && event.target.files[0]){
          var type = event.target.files[0].type;
          if(type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
              this.fileData = event.target.files[0];
              this.uploadImageFunc()
              var reader = new FileReader()
              reader.onload = (e)=> {
              }
          } else {
              this.service.showErrorMessage("Select only jpg,jpeg and png file.");
              self.fileData = "";
          }
      }
}

uploadImageFunc() {
  let formdata = new FormData()
  formdata.append('file',this.fileData)
  this.service.showSpinner();
  this.service.postCandyPixelForms('account/upload-file', formdata).subscribe(res => {
    this.service.hideSpinner();
    if (res['status'] == 200) {
      this.imageUrl2 = res['data'];
    }
  }, err => {
    this.service.hideSpinner();
  })
}


  // Update Profile Data Functionality
  updateFunc() {
    var apireq = {
      "address": this.profileForm.value.address,
      "firstName": this.profileForm.value.firstName,
      "imageUrl": this.imageUrl2,
      "lastName": this.profileForm.value.lastName,
      //"state": this.profileForm.value.state,
      "phoneNo": this.profileForm.value.mobile,
      "zipCode": this.profileForm.value.zipcode
      
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/profile-update', apireq).subscribe(res => {
      this.service.hideSpinner();
      this.getProfile();
      this.service.showSuccessMessage('User details updated');
    }, err => {
      this.service.hideSpinner();
    })
  }




  kyc() {
    this.router.navigate(['/kyc'])
  }
  security() {
    this.router.navigate(['/security'])
  }
  kyclist() {
     this.router.navigate(['/kyclist'])
  }
  addaccount() {
    this.router.navigate(['/add-account'])
  }
  changepassword() {
    this.router.navigate(['/change-password'])
  }
  googleauth() {
    this.router.navigate(['/google-auth'])
  }
  verifyAccount() {
    this.router.navigate(['/kyc'])
  }


  navigateToBy(path) {
   
    this.router.navigateByUrl(path);
  }

  ///SECURITY
   /** Function to restrict space */
   restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }

  // Get Profile for 2fa status
  // getProfile() {
  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms('account/my-account').subscribe(res => {
  //     if (res['status'] == 200) {
  //       this.profileData = res['data'];
  //       this.service.name = this.profileData.firstName
  //       this.service.hideSpinner();
  //     }
  //     else {
  //       this.service.hideSpinner();
  //       this.service.showErrorMessage(res['message']);
  //     }
  //   }, err => {
  //     this.service.hideSpinner();
  //     this.service.showErrorMessage(err['message']);
  //   })
  // }

  // Enable Google Auth Functionality 
  enableGoogleAuth() {
    if (this.profileData.twoFaType == 'SKIP' || this.profileData.twoFaType == 'NONE') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/google-auth').subscribe(res => {
        if (res['status'] == 200) {
          this.qrCode = res['data']['qrCode'];
          this.secretKey = res['data']['secretKey'];
          $('#googleAuth').modal({ backdrop: 'static', keyboard: false });
          this.service.hideSpinner();
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else {
      this.service.showErrorMessage('Disable SMS Auth First');
    }
  }

  verifyGoogleAuth() {
    var url = 'account/verify-google-code';
    var apireq = {
      "code": this.googleAuth,
      "secretKey": this.secretKey,
      "ipAddress": this.ipAddress,
      "source": "WEB"
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.qrCode = '';
        this.secretKey = '';
        this.googleAuth = '';
        this.getProfile();
        this.service.showSuccessMessage('Google Auth Enabled Successfully');
        $('#googleAuth').modal('hide');
      }
      else {
        this.service.showErrorMessage('Google Authentication code wrong');
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage('Something Went Wrong');
    })
  }

  //Disable Google Security
  openGoogleAuthModal() {
    this.googleAuth = '';
    $('#googleAuthDisable').modal({ backdrop: 'static' });
  }
  disableTwoFA() {
    var apireq = {
      'code': this.googleAuth, //this.authCode,
      "ipAddress": this.ipAddress,
      "source": "WEB"
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/twoFa-disable', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#googleAuthDisable').modal('hide');
        this.getProfile();
        this.googleAuth = '';
        this.service.showSuccessMessage('Google Auth Disabled Successfully')
      }
      else {
        this.service.showErrorMessage('Google Authentication code wrong')
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message'])
    })
  }

  // Enable SMS Auth Functionality
  openSMSAuthModal() {
    console.log("profile data::", this.profileData)
    this.smsAuth = '';
    if (this.profileData.twoFaType != 'GOOGLE') {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          $('#smsAuth').modal({ backdrop: 'static' });
          this.service.showSuccessMessage(res['message']);
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '500') {
          this.service.showErrorMessage('Mobile Number Not Verified');
        }
        else {
          this.service.showErrorMessage(err['message']);
        }
      })
    }
    else {
      this.service.showErrorMessage('Disable Google Auth First');
    }
  }

  verifySMSAuth() {
    var url = 'account/verify-sms-code';
    var smsapireq = {
      'code': this.smsAuth,
      'ipAddress': this.ipAddress,
      'source': 'WEB'
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.smsAuth = '';
        this.getProfile();
        this.service.showSuccessMessage(res['message']);
        $('#smsAuth').modal('hide');
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage('Something Went Wrong');
    })
  }

  // Disable SMS Auth Functionality
  disableSMSAuth() {
    var apireq = {
      'code': this.smsAuth, //this.authCode,
      'ipAddress': this.ipAddress,
      'source': 'WEB'
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/sms-auth-disable', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#smsAuth').modal('hide');
        this.getProfile();
        this.smsAuth = '';
        $('#smsAuth').modal('hide');
        this.service.showSuccessMessage('SMS Auth Disabled Successfully')
      }
      else {
        this.service.showErrorMessage(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      this.service.showErrorMessage(err['message'])
    })
  }

  // navigate to other sections
  profile() {
    this.router.navigate(['/profile'])
  }
 
  disablesms() {
    this.router.navigate(['/disable-sms'])
  }

  more() {
    this.router.navigate(['/login-activity'])
  }
  removeToken(){
    
  }

  // get IP Address
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  //KYC LIST
  // to get kyc status
checkStatus() {
  this.service.getCandyPixelForms('account/get-all-kyc-details').subscribe((res) => {
    let kycdata = {}
    kycdata = res['data']

    this.resultArr = kycdata['document'];
    if (kycdata && this.resultArr != null) {
      if ((kycdata['kycStatus'] == 'PENDING') || (kycdata['kycStatus'] == 'ACCEPTED')) {
        this.kycStatus = true
        console.log(this.resultArr);
        
        this.KycForm.patchValue({
          id_name : this.resultArr[0].docName,
          id_number : this.resultArr[0].docIdNumber,
        
        })
        this.imageUrl = this.resultArr[0].frontIdUrl,
        this.imageUrl1 = this.resultArr[0].backIdUrl
      } else if (kycdata['kycStatus'] == 'REJECTED') {
        this.kycStatus = false
      }
    }
    if (this.resultArr == null) {
      this.resultArr = []
    }
  }, (err) => {

  })
}


///KYC
UserDetailsForm: any;
UserDetailsForm1: any;
//profileData: any = {};
//arr: any = [];
countryList: any = [];
stateList: any = [];
countryCode: any;
states: any;
KycForm: FormGroup;
fileName1: any = 'Upload Front Page Of Your Passport or National Id'
fileName2: any = 'Upload Back Page Of Your Passport or National Id'
docArr: any = []
//kycStatus: boolean = false;
//resultArr: any = [];
imageUrl: any='assets/images/addharfront.jpg';
imageUrl1: any='assets/images/addharback.jpeg';
 // top validate user details form
 validateUserDetailsForm() {
  this.UserDetailsForm = new FormGroup({
    'fname': new FormControl('', [Validators.required, Validators.pattern(/^[a-z ,.'-]+$/i)]),  
    'phone': new FormControl('', [Validators.required]),
    'gender': new FormControl('', [Validators.required]),
    'country': new FormControl('', [Validators.required]),
    // 'state': new FormControl('', [Validators.required]),   
    'term': new FormControl(true, [Validators.required]),
    'privacy': new FormControl(true, [Validators.required]),
    'lname': new FormControl('', [Validators.pattern(/^[a-z ,.'-]+$/i)]),
    'email_add': new FormControl('', [Validators.required, Validators.maxLength(64), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/i)]),
    'city': new FormControl('', [Validators.required]),
    'address': new FormControl(true, [Validators.required])
    
  })

  this.KycForm = new FormGroup({
    'attachmentName1': new FormControl('', [Validators.required]),
    'attachmentName2': new FormControl(''),
    'id_name': new FormControl('', [Validators.required]),
    'id_number': new FormControl('', [Validators.required]),
  })
}
/** to get the value of field  */
get fname(): any {
  return this.UserDetailsForm.get('fname');
}
get lname(): any {
  return this.UserDetailsForm.get('lname');
}
get email_add(): any {
  return this.UserDetailsForm.get('email_add');
}
get phone(): any {
  return this.UserDetailsForm.get('phone');
}
get gender(): any {
  return this.UserDetailsForm.get('gender');
}
get country(): any {
  return this.UserDetailsForm.get('country');
}
get address(): any {
  return this.UserDetailsForm.get('address');
}
get city(): any {
  return this.UserDetailsForm.get('city');
}
get term(): any {
  return this.UserDetailsForm.get('term');
}

get privacy(): any {
  return this.UserDetailsForm.get('privacy');
}

// Get StateList Functionality
getStateList(event) {
  var stateList = this.service.countryListJson.filter(x => (x.country == event)); 
  this.stateList = stateList[0].states;
  this.countryCode = stateList[0].code;
 
}


 /** To upload document 1 -- Front*/
 handleFileInput1(event, index) {
  this.service.showSuccessMessage('Please wait! Upload in progress...')
  var self = this;
  if (event.target.files && event.target.files[0]) {
    var type = event.target.files[0].type;
    if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg') {
      let fileData = event.target.files[0];
      if (index == '1')
        this.fileName1 = event.target.files[0].name;
      else if (index == '2')
        this.fileName2 = event.target.files[0].name;
     
      this.sendFormData1(fileData, index)
      
      var reader = new FileReader()
    } else {
      this.service.showErrorMessage("Select only jpg,jpeg and png file.");
    }
  }
}

/** to call form data */
sendFormData1(fileData, index) {
  let formdata = new FormData()
  formdata.append('file', fileData);
  this.service.postApi('account/upload-file', formdata).subscribe((succ) => {
    if (succ.status == 200) {
      this.service.hideSpinner();
      this.service.showSuccessMessage('File Uploaded')
      let url = succ.data
      if(index == '1'){
        this.imageUrl=succ.data
      }
     else{
      this.imageUrl1=succ.data
     }  
      var data = {};
      if (index == '1' || index == '2') {
        data = {
          "name": this.KycForm.value.id_name,
          "number": this.KycForm.value.id_number,
          "url": url,
          "documentNumber": index
        }
      }
      else {
        data = {
          "name": 'Selfie with Id',
          "number": 'NA',
          "url": url,
          "documentNumber": index
        }
      }
      this.docArr[Number(index)] = data
    } else {
      this.service.showSuccessMessage(succ.message)
    }
  }, error => {
    if (index == '1'){
      this.fileName1 = 'Upload Front Page Of Your 1st ID';
    }   
    else if (index == '2')
      this.fileName2 = 'Upload Front Page Of Your 2nd ID';
    this.service.hideSpinner();
  })
}

// to get kyc status
// checkStatus() {
//   this.service.getCandyPixelForms('account/get-all-kyc-details').subscribe((res) => {
//     let kycdata = {}
//     kycdata = res['data']

//     this.resultArr = kycdata['document'];
//     if (kycdata && this.resultArr != null) {
//       if ((kycdata['kycStatus'] == 'PENDING') || (kycdata['kycStatus'] == 'ACCEPTED')) {
//         this.kycStatus = true
//       } else if (kycdata['kycStatus'] == 'REJECTED') {
//         this.kycStatus = false
//       }
//     }
//     if (this.resultArr == null) {
//       this.resultArr = []
//     }
//   }, (err) => {

//   })
// }
/** to submit kyc details */
submitKYC() {
  this.docArr.shift();
  var documentData = [];
  documentData.push({
    'backIdUrl': this.docArr[1].url,
    'createTime': new Date(),
    'docIdNumber': this.docArr[0]['number'],
    'docName': this.docArr[0]['name'],
    'documentId': 0,
    'documentNumber': 1,
    //'documentStatus':'',
    'frontIdUrl': this.docArr[0].url,
    'latest': true,
    'reason': '',
    'updateTime': new Date()
  })
  // documentData.push({
  //   'backIdUrl': 'NA',
  //   'createTime': new Date(),
  //   'docIdNumber': this.docArr[2].number,
  //   'docName': this.docArr[2].name,
  //   'documentId': 0,
  //   'documentNumber': 2,
  //   //'documentStatus':'',
  //   'frontIdUrl': this.docArr[2].url,
  //   'latest': true,
  //   'reason': '',
  //   'updateTime': new Date()
  // })

  let data = {
    "document": documentData,
  }
  if (this.docArr[0].url && this.docArr[1].url) {
    this.service.postApi('account/save-kyc-details', data).subscribe((succ) => {
      if (succ.status == 200) {
        this.service.showSuccessMessage('KYC Submitted successfully')
        this.kycStatus = true;
        this.checkStatus();
       // this.router.navigate(['/kyclist']);
      } else {
        this.service.showErrorMessage(succ.message)
      }
    }, error => {
      this.service.showErrorMessage(error.error.message)
    })
  //} else {
    //this.service.showErrorMessage('Re-upload again!')
  //}
}

}

}
