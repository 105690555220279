import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  isLoggedIn:any;
  privacyData: any;
  constructor(public service: MainService,public sanitizer: DomSanitizer) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('credential')? localStorage.getItem('credential'):''
    this.getPrivacy()
  }
  getPrivacy(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=PRIVACY%20POLICY').subscribe(res=>{
           if (res['status']==200){
             this.privacyData=res['data'].pageData;
             this.service.hideSpinner()
             this.service.showSuccessMessage(res['message'])
           }
           if (res['status']==201){
             this.service.hideSpinner()
            this.service.showErrorMessage('No Data Found')
          }
    })
   }
}
