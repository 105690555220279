import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-google-authenication-step4',
  templateUrl: './google-authenication-step4.component.html',
  styleUrls: ['./google-authenication-step4.component.css']
})
export class GoogleAuthenicationStep4Component implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }


  navigateToBy(path) {
    this.router.navigateByUrl(path);
  }
}
