import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-carrier',
  templateUrl: './carrier.component.html',
  styleUrls: ['./carrier.component.css']
})
export class CarrierComponent implements OnInit {
  isLoggedIn: any;
  carrierData: any;
  constructor(public service: MainService) { }
  // carrierData:any
  // constructor(private service:MainService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.isLoggedIn = localStorage.getItem('credential') ? localStorage.getItem('credential') : ''
    this.getCarrierData()
  }

  getCarrierData() {
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=CARRIER').subscribe(res => {
      if (res['status'] == 200) {
        this.carrierData = res['data'].pageData;
        this.service.hideSpinner()
        this.service.showSuccessMessage(res['message'])
      }
      if (res['status'] == 201) {
        this.service.hideSpinner()
        this.service.showErrorMessage('No Data Found')
      }
    })
  }
}
