import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
import { IMyDpOptions } from 'mydatepicker';

declare var $: any;
@Component({
  selector: 'app-signup-details',
  templateUrl: './signup-details.component.html',
  styleUrls: ['./signup-details.component.css']
})
export class SignupDetailsComponent implements OnInit {

  signupData: any = {};
  imageUrl: any = "assets/images/logo.png";
  countryList: any = [];
  stateList: any = [];
  signupTwoForm: FormGroup;
  countryCode: any;
  CodeList: any;
  CountryCodeList: any;
  TelephoneNo: boolean = true;
  accountData: any;

  constructor(public router: Router,
    public service: MainService,
  ) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    if (localStorage.getItem('signupData')) {
      this.signupData = this.service.decryptData(localStorage.getItem('signupData'));
    }
    this.formValidation();
    this.onDateChanged();
    this.countryList = this.service.countryListJson;
  }

  // Form Validation
  formValidation() {
    this.signupTwoForm = new FormGroup({
      'firstName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/i)]),
      'middleName': new FormControl('', [Validators.pattern(/^[a-zA-Z]*$/i)]),
      'lastName': new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z]*$/i)]),
      //'image': new FormControl('',Validators.required),
      'dob': new FormControl('', Validators.required),
      'address': new FormControl('', [Validators.maxLength(150), Validators.required]),
      'selectCountry': new FormControl('', Validators.required),
      'selectState': new FormControl('', Validators.required),
      'selectCity': new FormControl('', [Validators.required]),
      'mobile': new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/i)]),
      //'twoFA': new FormControl('',Validators.required)
    })
    // let dob = new Date()
    // let mydate = {
    //     date: {
    //         year: dob.getFullYear() - 18,
    //         month: dob.getMonth() + 1,
    //         day: dob.getDate()}
    //     }
    // this.signupTwoForm.patchValue({"dob": mydate});       
    // this.signupTwoForm.patchValue({dob});                

  }
  // Image Functionality Start Here
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }

  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  // Date of Birth Management
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
    height: '45px',
  };
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear() - 18,
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  //Returns copy of myDatePickerOptions
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
  // Signup Api Integration
  signupFunc() {
    var apireq = {
      "address": this.signupTwoForm.value.address,
      "city": this.signupTwoForm.value.selectCity,
      "country": this.signupTwoForm.value.selectCountry,
      "code": this.signupTwoForm.value.countryCode,
      "dob": this.signupTwoForm.value.dob.formatted,
      "email": this.signupData.email,
      "firstName": this.signupTwoForm.value.firstName,
      "imageUrl": this.imageUrl,
      "lastName": this.signupTwoForm.value.middleName + " " + this.signupTwoForm.value.lastName,
      "password": this.signupData.password,
      "phoneNo": this.countryCode + this.signupTwoForm.value.mobile,
      "countryCode": this.countryCode,
      "pnWithoutCountryCode": this.signupTwoForm.value.mobile,
      "roleStatus": "USER",
      "state": this.signupTwoForm.value.selectState,
      'gender': 'MALE',
      "webUrl": this.service.webUrl
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/signup', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        localStorage.removeItem('signupData')
        this.service.showSuccessMessage(' Verification link has been send to your registered email id');
        //this.router.navigate(['/login'])
        this.router.navigate(['/login'])
      }
      else if (res['status'] == 205) {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.showErrorMessage('Something Went Wrong');
      this.service.hideSpinner();
    })
  }
  // Get StateList Functionality
  getStateList(event) {
    var stateList = this.countryList.filter(x => (x.country == event.target.value));
    this.stateList = stateList[0].states;
    this.countryCode = stateList[0].code;
  }


  puzzlemodal(){
    $('#puzzle').modal('show');
   
  }
 
  verifyemail(){
    $('#puzzle').modal('hide');
    this.router.navigate(['/verify-email'])
  }
}
