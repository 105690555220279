import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { ClipboardService } from 'ngx-clipboard'
// import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
  coinList: any = [];
  walletAddress: any;
  coinName: any;
  coinWithdrawFee: any;
  sendForm: FormGroup;
  finalAmount: number = 0;
  tags: any;
  account: any;
  tagError: string;
  selectedUserCoin: any = {};
  depositePageNumber:number=1;
  qrCode: any = '';
  secretKey: any = '';
  googleAuth: any = '';
  twoFAStatus: any;
  smsAuth: any = '';
  profileData: any;
  usdtdata: any;
  btcdata: any;
  value: number=0;
  withdrawfee1: any;
  withdrawFee1: any;
  coinWithdrawAmount: any;
  coinamt: number;
  perCoinTotalBaln: any;
  receiveForm: FormGroup;
  id: string;
  currencyList: any=[];

 
  constructor(public router: Router,public service: MainService,private _clipboardService: ClipboardService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.id = localStorage.getItem('OrderId')
    this.getAllCoins()
    this.depositETH();
    this.formValidationFunctionality();
    this.getProfile();
    this.paymentValidationFunctionality()
    this.withdrawPaymentValidationFunctionality()
    this.RazorPayPaymentStatus()
    this.PaymentStatus()
   
   
  }
// formValidation Functionality
formValidationFunctionality() {
  this.sendForm = new FormGroup({
    'coinAmount': new FormControl('', [Validators.required, Validators.pattern((/^(\d+)?([.]?\d{0,8})?$/))]),
    'address': new FormControl('', Validators.required)
  })
  // this.receiveForm = new FormGroup({
  //   'walletAdd': new FormControl('',),
    
  // })
}

copyToClipboard(item) {
        
  this.service.showSuccessMessage('Wallet address copied successfully')
   document.addEventListener('copy', (e: ClipboardEvent) => {
     e.clipboardData.setData('text/plain', (item));
     e.preventDefault();
     document.removeEventListener('copy', null);
   });
   document.execCommand('copy');
 }
   // Get All The Coin Functionality
   getAllCoins() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(( res: any) => {
     
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {
         
          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            this.depositCoin(coinList[x].coinShortName);
            
          }
        }
        this.coinList = coinList;
        console.log("crypto coins:::", this.coinList)
        this.selectedUserCoin = this.coinList[0]
       
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  getallcoins1(){
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(( res: any) => {
      
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {
         
          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            //this.depositCoin(coinList[x].coinShortName);
          }
        }
        this.coinList = coinList;
        this.selectedUserCoin = this.coinList[0]
      
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }


  depositCoin(coinShortName) {
    var url = ''
    this.tags = '';
    this.account = ''
    //if (coinShortName == 'XRP' || coinShortName =='XLM'|| coinShortName =='OMG'|| coinShortName =='USDT'|| coinShortName =='ETH') {
    if (coinShortName == 'XRP' || coinShortName =='XLM'|| coinShortName =='OMG'||  coinShortName =='ETH') {
      url = 'wallet/wallet-type2/get-deposits?coinName=' + coinShortName+'&page='+(this.depositePageNumber - 1)+'&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
         // this.service.showError('Something Went Wrong');
        }
      })
    } 
    /*else if (coinShortName == 'USDT') {
      url = 'coin/get-usdt-network-balance';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.usdtdata= res['data'].USDT_balance
          this.btcdata= res['data'].BTC_balance
          if(this.usdtdata>=1 && this.btcdata>=0.0001){
          this.getusdtdepositlist(coinShortName);
          }
          else{
            this.service.showError('You should have USDT more than 1 and BTC more than 0.001 to perform deposit')
          }
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
         // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showError('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }*/
    else {
      url = 'wallet/wallet/get-deposits?coinName=' + coinShortName+'&page='+(this.depositePageNumber - 1)+'&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.getallcoins1();
          // this.account = res['data']['eosAccountName']
          // this.walletAddress = res['data'].walletAddress;
          //$('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
         // this.service.showError(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          //this.service.showError('Something Went Wrong');
        }
      })
    }
  }

   // Send & Recieve Coin Functionality
   sendCoin(coinShortName) {
    
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/coin/get-coin-details?coinName='+coinShortName).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.coinWithdrawFee = res['data'].withdrawlFee;
        this.coinWithdrawAmount = res['data'].withdrawalAmount;
       
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      
    })

    this.coinName = coinShortName;
    //this.coinWithdrawFee = this.withdrawfee1;
    this.sendForm.reset();
    //$('#sendModal').modal({ backdrop: 'static' })
  }
  getFinalAmount(event) {
    this.finalAmount = 0;
    if((/^(\d+)?([.]?\d{0,8})?$/).test(event.target.value)) {    
      // this.finalAmount = Number(event.target.value) - ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.finalAmount = Number(event.target.value) - this.coinWithdrawFee
      this.coinamt =  Number(event.target.value)
      // this.value = ((this.coinWithdrawFee / 100) * Number(event.target.value));
      this.value =this.coinWithdrawFee
    }
  }
  getTagValue(event) {
    this.tags = event.target.value;
  }
  sendCoinFunc() {
    var apiReq = {};
    var base;
    if (this.coinName =='OMG') {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base='wallet/wallet-type2/withdraw'
    }
    else if(this.coinName == 'USDT'){
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      // base='wallet/wallet-type2/withdraw-type3'
      base = 'wallet/wallet/withdraw'

    }
    else {
      apiReq = {
        "amount": this.sendForm.value.coinAmount,
        "coinName": this.coinName,
        "isWithdraw": false,
        "isKycAccepted": true,
        "tag": this.tags,
        "toAddress": this.sendForm.value.address,
        "url": this.service.webUrl + "/wallet-transaction-status"
      }
      base='wallet/wallet/withdraw'
    }
    
    this.service.showSpinner();
    this.service.postCandyPixelForms(base, apiReq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#sendModal').modal('hide');
        this.service.showSuccessMessage(res['message']);
        this.sendForm.reset();
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  resetForm() {
    
    this.sendForm.reset();
    this.value=0;
    this.finalAmount=0;
  }

  recieveCoin(coinShortName) {
    var url = ''
    this.tags = '';
    this.account = ''
    if (coinShortName == 'XRP' || coinShortName == 'XLM') {
      url = 'wallet/wallet-type2/get-address?coinName=' + coinShortName;
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.walletAddress = res['data'].walletAddress;
          this.tags = res['data'].tag;
          $('#recieveModal').modal({ backdrop: 'static' })
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Kyc not done');
        }
      })
    } 
    else if (coinShortName == 'OMG') {
      url = 'wallet/wallet/get-address-for-erc20?coinName=' + coinShortName;
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.walletAddress = res['data'];
          $('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Kyc not done');
        }
      })
    } else {
      url = 'wallet/wallet/get-address?coinName=' + coinShortName;
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.account = res['data']['eosAccountName']
          this.walletAddress = res['data'].walletAddress;
          $('#recieveModal').modal({ backdrop: 'static' })
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Kyc not done');
        }
      })
    }
  }

  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/my-account').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.profileData = res['data']['twoFaType'];
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
    }

  }

  openModal() {
    this.googleAuth= '';
    this.smsAuth= '';
    if(this.coinamt < this.coinWithdrawAmount){
      this.service.showErrorMessage("Coin amount cannot be less than min. withdrawal amount")
    }
    else{
      if ( this.profileData == 'GOOGLE') {
        $('#googleAuth').modal({ backdrop: 'static' });
      } else if ( this.profileData== 'SMS') {
        this.suggesstionFunc('sms')
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileData == 'NONE' || this.profileData == 'SKIP') {
        this.service.showErrorMessage('Please Enable Two FA First.')
      }
    }
   
  }



  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == 'Google') {
      var apireq = {};
      var url = '';
      if (this.twoFAStatus == 'GOOGLE') {
        url = 'account/verify-google-code';
        apireq = {
          "code": this.googleAuth,
          "secretKey": this.secretKey
        }
       } //account/verify-google-code
       else {
        url = 'auth/verify-google';
        apireq = {
          "otp": Number(this.googleAuth),
          //"secretKey": this.secretKey
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, apireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] ==  200 ) {
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'GOOGLE') {
            //this.service.changeLoginSub('login');
            localStorage.setItem('credential', res['data']);
            sessionStorage.setItem('secretKey', this.secretKey);
            
          }
          this.service.showSuccessMessage(res['message']);
          $('#googleAuth').modal('hide');
          this.googleAuth= '';
          this.sendCoinFunc();
         // this.routes.navigate(['/landing']);
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
      })

    }
    else if (select == 'sms') {
      var smsapireq = {};
      var url = '';
      if (this.twoFAStatus == 'SMS') {
        url = 'auth/verify-sms';
        smsapireq = {
          'otp': this.smsAuth
        }
       } 
      else {
        url = 'account/verify-sms-code';
        smsapireq = {
          'code': this.smsAuth
        }
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms(url, smsapireq).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] ==  200 ) {
          //this.service.changeLoginSub('login');
          localStorage.removeItem('authToken')
          if (this.twoFAStatus == 'SMS')
         
            localStorage.setItem('credential', res['data']);
          this.service.showSuccessMessage(res['message']);
          this.sendCoinFunc();
          //this.routes.navigate(['/landing']);
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        this.service.showErrorMessage('Something Went Wrong');
      })
    }}






   /** Function to restrict space */
   restrictSpace(event) {
    var k = event.charCode;
    if (k === 32) return false;
  }

  /** Function to restrict character */
  restrictChar(event) {
    var k = event.charCode;
    if (event.key === 'Backspace')
      k = 8;
    if (k >= 48 && k <= 57 || k == 8 || k == 46)
      return true;
    else
      return false;
  }
// to remove token
removeToken() {
  localStorage.removeItem('authToken')
}


 // 2FA Suggestion Modal Functionality
 suggesstionFunc(action) {
  this.googleAuth= '';
  if (action == 'Skip') {
    this.service.showSpinner();
    this.service.getCandyPixelForms('account/skip-twoFa').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] ==  200 ) {
        this.service.showSuccessMessage('Logged In');
        $('#suggest').modal('hide');
        //this.service.changeLoginSub('login');
        let credential = localStorage.getItem('authToken')
        localStorage.removeItem('authToken')
        localStorage.setItem('credential', credential);
        this.router.navigate(['']);
      }
      else {
        this.service.showErrorMessage(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  else if (action == 'Google') {
    this.service.showSpinner();
    this.service.getCandyPixelForms('account/google-auth').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] ==  200 ) {
       // this.qrCode = res['data']['qrCode'];
        this.secretKey = res['data']['secretKey'];
        $('#suggest').modal('hide');
        $('#googleAuth').modal('show');
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  else if (action == 'sms') {
    this.service.showSpinner();
    this.service.getCandyPixelForms('account/send-sms-code').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] ==  200 ) {
        $('#suggest').modal('hide');
        $('#smsAuth').modal('show');
        
        this.service.showSuccessMessage(res['message']);
      }
      else {
        this.service.showErrorMessage(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '500') {
        this.service.showErrorMessage('Mobile Number Not Verified');
      }
      else {
        this.service.showErrorMessage(err['message']);
      }
    })
  }
}

   // Resend Email For Verification
  //  resSendEmail() {
  //   var url = "account/resend-verify-email?email=" + this.loginForm.value.email + "&webUrl=" + this.service.webUrl + "/landing";
  //   this.service.showSpinner();
  //   this.service.getCandyPixelForms(url).subscribe(res => {
  //     this.service.hideSpinner();

  //   }, err => {
  //     this.service.hideSpinner();
  //   })
  // }
  // Copy Functionality
  copy() {
    
      this._clipboardService.copy(this.walletAddress)
      this.service.showSuccessMessage('Wallet Address Copied Successfully');
    
    // } else  (val == 2) {
    //   this._clipboardService.copyFromContent(this.sendForm.value.uniqueId)
    //   this.service.showSuccessMessage('Tag Id Copied Successfully');
    // } 
  }

  
  // selectedCoin Functionality
  selectedCoin(coinname, maxtotalbaln) {
    this.selectedUserCoin = coinname;
    this.perCoinTotalBaln = maxtotalbaln
   
    $('#sendModal').modal({ backdrop: 'static' })
    this.sendCoin(coinname)
  }


  getDepositePage(pageNumber){
    this.depositePageNumber = pageNumber - 1;
    this.depositCoin(this.coinList);
    }


  getusdtdepositlist(coinShortName){
    var url = ''
    this.tags = '';
    this.account = ''
    url = 'wallet/wallet/get-deposits?coinName=' + coinShortName+'&page='+(this.depositePageNumber - 1)+'&pageSize=10';
    this.service.showSpinner();
    this.service.getCandyPixelForms(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getallcoins1();
        // this.account = res['data']['eosAccountName']
        // this.walletAddress = res['data'].walletAddress;
        //$('#recieveModal').modal({ backdrop: 'static' })
      }
      else {
       // this.service.showError(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        //this.service.showError('Something Went Wrong');
      }
    })
  }
  depositETH() {
    
       this.service.showSpinner();
       this.service.getCandyPixelForms("wallet/wallet/get-address?coinName=ETH", ).subscribe((res) => {
           this.service.hideSpinner();
           if (res['status'] == 200) {
               // this.appC.showInfoToast('Address fetched successfully.');
              //  this.coinFullName = succ.body.data.coinName;
              //  this.textValue = succ.body.data.walletAddress;
              //  this.myAngularxQrCode = succ.body.data.walletAddress;
              //  this.tagId = succ.tag;
               this.etherSuccess()
              // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
           } 
       }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
         // this.service.showError('Something Went Wrong');
        }
      })
   
}

   // Ether Success 
   etherSuccess(){
    this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res=>{
                
    },err=>{

    })
  }

  depositUSDT(){
    this.service.showSpinner();
    this.service.getCandyPixelForms("wallet/wallet/get-address?coinName=ETH", ).subscribe((res) => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
            // this.appC.showInfoToast('Address fetched successfully.');
           //  this.coinFullName = succ.body.data.coinName;
           //  this.textValue = succ.body.data.walletAddress;
           //  this.myAngularxQrCode = succ.body.data.walletAddress;
           //  this.tagId = succ.tag;
            this.etherSuccess()
           // $('#wallet-address').modal({backdrop: 'static', keyboard: false});
        } 
    }, err => {
     this.service.hideSpinner();
     if (err['status'] == '401') {
       localStorage.clear();
       this.router.navigate(['/login']);
       this.service.showErrorMessage('Session Expired!');
     } else {
      // this.service.showError('Something Went Wrong');
     }
   })
  }
  

  //open payment modal for wallet topup
  openPaymentModal(){
    $("#paymentModal").modal('show')
    //$("#cashFree").modal('show')
  }
  flag=0
  paymentMethod(method) {
    $("#paymentModal").modal('hide')

    if (method == 'razorPay') {
      $("#cashFree").modal('show')
      this.flag=1
      // this.buyPaymentRazor()
    } else {
      $("#cashFree").modal('show')
      this.flag=0
     
      this.buyPayment()
    }
  }
  paymentForm: FormGroup
  paymentValidationFunctionality() {
    this.paymentForm = new FormGroup({
      'fullName': new FormControl('', [Validators.required,  Validators.pattern(/^[a-z A-Z]*$/i)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'mobileNumber': new FormControl('', [Validators.required,Validators.pattern(/^[0-9]*$/i)]),
      'amount': new FormControl('', [Validators.required,Validators.pattern(/^[0-9]*$/i)]),
      'selectCoin': new FormControl('', Validators.required)
     
    })
  }
  orderid:any
  depositToWallet(){
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/payout/v1/request-deposit-withdraw-wallet?amount='+this.paymentForm.value.amount + '&coinName='+this.paymentForm.value.selectCoin + '&transactionType='+'DEPOSIT' ,1).subscribe(res => {
     
      if (res['status'] == 200) {
        $("#cashFree").modal('hide')
       this.orderid = res['data']['randomId']
       this.getPaymentLink()
     
       setTimeout(() => {
        this.buyPayment()
      }, 3000);
       
        this.service.hideSpinner();
       
        // this.history()
        // this.otcBuyForm.reset();
      }
      else{
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  getPaymentLink() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('checkout/v1/get-payment-link?orderId=' + localStorage.getItem("OrderId")).subscribe(res => {
      if (res['status'] == 200) {
        // this.paymentData=res['data']
        // this.status =  this.paymentData.txStatus
        this.paymentLink= res['data']['paymentLink']
        
        //this.service.showSuccessMessage('Success')
        // window.open(this.paymentLink)
        // this.history()
        // this.otcBuyForm.reset();
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  // depositToWalletRazorPay(){
  //   this.service.showSpinner();
  //   this.service.postCandyPixelForms('wallet/payout/v1/request-deposit-withdraw-wallet?amount='+this.paymentForm.value.amount + '&coinName='+this.paymentForm.value.selectCoin + '&transactionType='+'DEPOSIT' ,1).subscribe(res => {
     
  //     if (res['status'] == 200) {
  //       $("#cashFree").modal('hide')
  //      this.orderid = res['data']['randomId']
  //      localStorage.setItem('OrderId', this.orderid)
     
  //      setTimeout(() => {
  //       this.buyRazorPay()
  //     }, 3000);
       
  //       this.service.hideSpinner();
       
      
  //     }
  //     else{
  //       this.service.hideSpinner();
  //     }
  //   }, err => {
  //     this.service.hideSpinner();
  //   })
  // }

  razorpayPaymentLink:any
  buyRazorPay(){
      var paymentData={
        "amount": parseInt(this.paymentForm.value.amount)* 100,
        "currency": this.paymentForm.value.selectCoin,      
      }
     
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/checkout/v1/razorpay/create-payment', paymentData).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
         this.razorpayPaymentLink = res['data']['short_url']
         localStorage.setItem('razorpayPlinkId',res['data']['id'])
         window.open(this.razorpayPaymentLink)
        this.service.hideSpinner();
        $("#cashFree").modal('hide')
        // this.history()
        // this.otcBuyForm.reset();
       // this.service.showSpinner();
        // setTimeout(() => {
        //   this.service.hideSpinner();
        //   this.PaymentStatus()
        // }, 3000);
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  RazorPayPaymentStatus() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/checkout/v1/razorpay/update-payment-status?plink_id=' +localStorage.getItem('razorpayPlinkId')).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
       // this.paymentData=res['data']
       // this.status =  this.paymentData.txStatus
        //this.paymentLink= res['data']['paymentLink']
       
        //this.service.showSuccessMessage('Success')
       // window.open(this.paymentLink)
       // this.history()
        // this.otcBuyForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }


  paymentLink:any
  buyPayment() {
   
    var data = {
      "customerEmail": this.paymentForm.value.email,
      "customerName": this.paymentForm.value.fullName,
      "customerPhone": '+91' + this.paymentForm.value.mobileNumber,
      "notifyUrl": '' ,
      "orderAmount": parseInt(this.paymentForm.value.amount),
      "orderCurrency": this.paymentForm.value.selectCoin,
      "orderId": this.orderid.toString(),
      "orderNote": "string",
      "returnUrl":"http://localhost:4200/payment-status"
      // "returnUrl": ''
      //"returnUrl":''
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/checkout/v1/create-payment', data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.paymentLink = res['data']['paymentLink']
        this.paymentForm.reset()
        this.service.showSpinner()
        window.open(this.paymentLink)
        this.service.hideSpinner();
        // this.history()
        // this.otcBuyForm.reset();
        this.service.showSpinner();
        // setTimeout(() => {
        //   this.service.hideSpinner();
        //   this.PaymentStatus()
        // }, 3000);
      }
    }, err => {
      this.service.hideSpinner();
    })
  }


  // Withdraw payment section

  withdrawPaymentForm: FormGroup
  withdrawPaymentValidationFunctionality() {
    this.withdrawPaymentForm = new FormGroup({
      'fullName': new FormControl('', [Validators.required,  Validators.pattern(/^[a-z A-Z]*$/i)]),
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'mobileNumber': new FormControl('', [Validators.required,Validators.pattern(/^[0-9]*$/i)]),
      'amount': new FormControl('', [Validators.required,Validators.pattern(/^[0-9]*$/i)]),
      'selectCoin': new FormControl('', Validators.required)
     
    })
  }
  openWithdrawModal(){
    $("#withDrawModal").modal('show')
  }

  withdrawWallet(){
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/payout/v1/request-deposit-withdraw-wallet?amount='+this.withdrawPaymentForm.value.amount + '&coinName='+this.withdrawPaymentForm.value.selectCoin + '&transactionType='+'WITHDRAW' ,1).subscribe(res => {
     
      if (res['status'] == 200) {
        $("#withDrawModal").modal('hide')     
        this.service.hideSpinner();
        this.service.showSuccessMessage('Withdraw request sent to admin for Approval, kindly check your account balance after some time.')
        this.withdrawPaymentForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  PaymentStatus() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/checkout/v1/get-payment-status?orderId=' +this.id).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
       // this.paymentData=res['data']
       // this.status =  this.paymentData.txStatus
        //this.paymentLink= res['data']['paymentLink']
       
        //this.service.showSuccessMessage('Success')
       // window.open(this.paymentLink)
       // this.history()
        // this.otcBuyForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

 
}


