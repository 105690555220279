import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  token: string;
  constructor(public router: Router,public service: MainService) { }
  tax1Type:any
  tax2Type:any
  
  ngOnInit() {
    this.tax1Type=false
    this.tax2Type=false
    window.scrollTo(0, 0)
    this.token = window.location.href.split('=')[1];
    this.resetForm = new FormGroup({
      'password': new FormControl('',Validators.required),
      'confirmpassword': new FormControl('',Validators.required)
    })
  }
  eyeShow(type){
    if(type==1){
      this.tax1Type =!this.tax1Type
    }else{
    this.tax2Type =!this.tax2Type
    }

  }
  resetFunc(){
    var apiDoc = {
      "password": this.resetForm.value.password,
      "token":this.token
    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('account/reset-password',apiDoc).subscribe(res=>{
      if(res['status'] == 200)
      {
        this.service.hideSpinner();
      this.service.showSuccessMessage('Password Reset Successfully');
    this.router.navigate(['/login'])
  }
    }, err=>{
     this.service.hideSpinner();
     this.service.showErrorMessage('Something Went wrong');
    })
  }

}
