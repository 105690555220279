import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';



@Component({
  selector: 'app-enabled-sms-authenticator',
  templateUrl: './enabled-sms-authenticator.component.html',
  styleUrls: ['./enabled-sms-authenticator.component.css']
})
export class EnabledSmsAuthenticatorComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
  }

  navigateToBy(path) {
    this.router.navigateByUrl(path);
  }
}
