import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/main.service';

@Component({
  selector: 'app-withdraw-history',
  templateUrl: './withdraw-history.component.html',
  styleUrls: ['./withdraw-history.component.css']
})
export class WithdrawHistoryComponent implements OnInit {
  coinList: any = [];
  selectedUserCoin: any = {};
  withdrawList: any = [];
  total: any;
  withdrawPageNumber: number = 1;
  constructor(public router: Router,public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getListOfCoin()
  }

   // Get All The Coin Functionality
   getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(( res: any) => {
     
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {
         
          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
           
            
          }
        }
        this.coinList = coinList;
        
        this.selectedUserCoin = this.coinList[0].coinShortName
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  selectcoin(coin){
    this.selectedUserCoin=coin
    this.getTrasactionList()
  }
   // Get Transaction List Functionality
   getTrasactionList() {
    this.withdrawList = [];
      var url = 'wallet/wallet/get-withdrawlist?coinName=' + this.selectedUserCoin + '&page=' + (this.withdrawPageNumber -1 ) + '&pageSize=10';
      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.withdrawList = res['data']['resultlist'];
          this.total = res['data']['totalCount'];
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Kyc not done!');
        }
      })
    
  }

  getWithdrawPage(pageNumber) {
    this.withdrawPageNumber = pageNumber ;
    this.getTrasactionList();
    //  console.log('????????',pageNumber,this.withdrawPageNumber)
  }
}
