import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disabled-sms-authenticator',
  templateUrl: './disabled-sms-authenticator.component.html',
  styleUrls: ['./disabled-sms-authenticator.component.css']
})
export class DisabledSmsAuthenticatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
