import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SignupDetailsComponent } from './components/signup-details/signup-details.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { SupportComponent } from './components/support/support.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FaqComponent } from './components/faq/faq.component';
import { BuySellComponent } from './components/buy-sell/buy-sell.component';
import { MarketComponent } from './components/market/market.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { WalletComponent } from './components/wallet/wallet.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DepositHistoryComponent } from './components/deposit-history/deposit-history.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AddBankComponent } from './components/add-bank/add-bank.component';
import { MyBankComponent } from './components/my-bank/my-bank.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { WithdrawHistoryComponent } from './components/withdraw-history/withdraw-history.component';
import { AccountActivityComponent } from './components/account-activity/account-activity.component';

import { DisabledSmsAuthenticatorComponent } from './components/disabled-sms-authenticator/disabled-sms-authenticator.component';
import { EnabledSmsAuthenticatorComponent } from './components/enabled-sms-authenticator/enabled-sms-authenticator.component';
import { GoogleAuthenicationStep1Component } from './components/google-authenication-step1/google-authenication-step1.component';
import { GoogleAuthenicationStep2Component } from './components/google-authenication-step2/google-authenication-step2.component';
import { GoogleAuthenicationStep3Component } from './components/google-authenication-step3/google-authenication-step3.component';
import { GoogleAuthenicationStep4Component } from './components/google-authenication-step4/google-authenication-step4.component';
import { AdvanceExchangeComponent } from './components/advance-exchange/advance-exchange.component';
import { CarrierComponent } from './components/carrier/carrier.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { TutorialsComponent } from './components/tutorials/tutorials.component';
// import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';

import { WalletTransactionStatusComponent } from './components/wallet-transaction-status/wallet-transaction-status.component';
import { MyDatePickerModule } from 'mydatepicker';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AmChartsModule } from '@amcharts/amcharts3-angular';
import { OtcComponent } from './components/otc/otc.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { StaticDesclaimerComponent } from './component/static-desclaimer/static-desclaimer.component';



@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LoginComponent,
    SignupComponent,
    SignupDetailsComponent,
    ForgetPasswordComponent,
    AboutUsComponent,
    PageNotFoundComponent,
    ContactUsComponent,
    SupportComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    BuySellComponent,
    MarketComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    WalletComponent,
    ProfileComponent,
    DepositHistoryComponent,
    ChangePasswordComponent,
    AddBankComponent,
    MyBankComponent,
    TermsConditionsComponent,
    WithdrawHistoryComponent,
    AccountActivityComponent,

    DisabledSmsAuthenticatorComponent,
    EnabledSmsAuthenticatorComponent,
    GoogleAuthenicationStep1Component,
    GoogleAuthenicationStep2Component,
    GoogleAuthenicationStep3Component,
    GoogleAuthenicationStep4Component,
    AdvanceExchangeComponent,
    CarrierComponent,
    HowItWorksComponent,
    TutorialsComponent,
    WalletTransactionStatusComponent,
    OtcComponent,
    PaymentStatusComponent,
    StaticDesclaimerComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      positionClass: 'toast-top-center'
    }),
    BrowserAnimationsModule, // required animations module
    // ToastrModule.forRoot(),
    NgxSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ClipboardModule,
    NgxQRCodeModule,
    MyDatePickerModule,
    NgxCaptchaModule,
    AmChartsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
