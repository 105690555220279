import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-deposit-history',
  templateUrl: './deposit-history.component.html',
  styleUrls: ['./deposit-history.component.css']
})
export class DepositHistoryComponent implements OnInit {
  coinList: any = [];
  selectedUserCoin: any = {};
  depositeList: any = [];
  totalDeposite: any;
  depositePageNumber: number = 1;
  withdrawPageNumber: number = 1;
  constructor(public router: Router,public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    this.getListOfCoin()
  }

   // Get All The Coin Functionality
   getListOfCoin() {
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(( res: any) => {
     
      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {
         
          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
           
            
          }
        }
        this.coinList = coinList;
        
        this.selectedUserCoin = this.coinList[0].coinShortName
        this.getTrasactionList();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.showErrorMessage('Session Expired!');
      } else {
        this.service.showErrorMessage('Something Went Wrong');
      }
    })
  }

  selectcoin(coin){
    this.selectedUserCoin=coin
    this.getTrasactionList()
  }

  getTrasactionList() {
    this.depositeList = [];
      if (this.selectedUserCoin == 'ETH' || this.selectedUserCoin == 'XRP' || this.selectedUserCoin == 'XLM'
      ||this.selectedUserCoin == 'OMG' || this.selectedUserCoin == 'USDT')
        var url = 'wallet/wallet-type2/get-deposits?coinName=' + this.selectedUserCoin + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
      else 
        // var url = 'wallet/wallet/get-deposits?coinName=' + this.selectedUserCoin.coinShortName + '&page=' + (this.depositePageNumber - 1) + '&pageSize=10';
        var url = 'wallet/wallet/get-deposits?coinName=' + this.selectedUserCoin + '&page=' + (this.withdrawPageNumber -1) + '&pageSize=10';

      this.service.showSpinner();
      this.service.getCandyPixelForms(url).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.depositeList = res['data']['resultlist']
          this.totalDeposite = res['data']['totalCount'];

          if (this.selectedUserCoin == 'ETH') {
            this.etherSuccess()
          }
        }
        else if(res['status'] == 205){
          this.service.showErrorMessage(res['message'])
        }
        else {
          this.service.showErrorMessage('Transaction Not Found');
        }
      }, err => {
        this.service.hideSpinner();
        if (err['status'] == '401') {
          localStorage.clear();
          this.router.navigate(['/login']);
          this.service.showErrorMessage('Session Expired!');
        } else {
          this.service.showErrorMessage('Kyc not done');
        }
      })    
  }

  // Set Deposite / Withdraw Page Number
  getDepositePage(pageNumber) {
    this.depositePageNumber = pageNumber;
    this.getTrasactionList();
    // console.log('????????',this.getTrasactionList())
  }

  // Ether Success 
  etherSuccess() {
    this.service.getCandyPixelForms('wallet/wallet-type2/get-async-transfer?coinName=ETH').subscribe(res => {
    }, err => {

    })
  }


}
