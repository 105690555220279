import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MainService } from 'src/app/main.service';
declare var $: any;

@Component({
  selector: 'app-otc',
  templateUrl: './otc.component.html',
  styleUrls: ['./otc.component.css']
})
export class OtcComponent implements OnInit {
  coinList: any = [];
  selectedUserCoin: any = {};
  availablebaln: any;
  otcBuyForm: FormGroup;
  selectBuyForm: FormGroup;
  selectSellForm: FormGroup;
  otcSellForm: FormGroup;
  selectedCoin: any;
  historyDetails: any = [];
  paymentLink: any;
  CustData: any = {};
  profileData: any;
  buyBase: any;
  buyBaseCoin: any;
  limit: any = 5;
  currPage: any = 1;
  rzp1: any;

  constructor(
    public router: Router, public service: MainService, private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getAllcoinsBeforeLogin()
    this.getProfile()
    this.getAllCoins()
    this.selectFormValidation()
    this.buyFormValidation()
    this.selectSellFormValidation()
    this.sellFormValidation()

    setTimeout(() => {
      this.history()
    }, 5000);


  }

  // Get Profile Name-Email
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/my-account').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.profileData = res['data'];

        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
  }

  //get all coins before login
  getAllcoinsBeforeLogin() {
    if (!localStorage.getItem('credential')) {
      let AllcoinList = []
      this.coinList = []
      this.service.showSpinner();
      this.service.getCandyPixelForms('wallet/coin/get-coin-list').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          AllcoinList = res['data'];
          AllcoinList.forEach(element => {
            this.coinList.push({
              'coinShortName': element.coinShortName
            })

          });

        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
      //console.log("coin names:::", this.coinList)
    }
  }



  getAllCoins() {
    this.coinList = []
    this.service.showSpinner();
    this.service.getCandyPixelForms('wallet/wallet/get-all-user-balance-and-coinlist').subscribe((res: any) => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (let x = 0; x < coinList.length; x++) {

          const m = res.data.userBalance.findIndex((ne) => (coinList[x].coinShortName === ne.instrument));
          if (m != -1) {
            coinList[x]['totalBalance'] = res['data'].userBalance[m].totalBalance;
            coinList[x]['availableBalance'] = res['data'].userBalance[m].availableBalance;
            coinList[x]['inOrderBalance'] = res['data'].userBalance[m].inOrderBalance;
            //this.depositCoin(coinList[x].coinShortName);

          }
        }
        this.coinList = coinList;
        // console.log("coin lists:: ", this.coinList)
        this.selectedUserCoin = this.coinList[0]

      }
    }, err => {
      this.service.hideSpinner();
      // if (err['status'] == '401') {
      //   localStorage.clear();
      //   this.router.navigate(['/login']);
      //   this.service.showErrorMessage('Session Expired!');
      // } else {
      //   this.service.showErrorMessage('Something Went Wrong');
      // }
    })
  }
  coinValue: any
  coinBalance: any
  getBalnc(event) {
    let coinData = event.target.value
    this.coinBalance = coinData.split(" ")[0]
    this.coinValue = coinData.split(" ")[1]
    this.getCoinDetails(this.coinValue)
    if (localStorage.getItem('credential')) {
      // console.log("EVENT:: ", event.target)
      this.availablebaln = coinData
      this.selectedCoin = this.coinValue
    }
    else {
      return this.availablebaln = 0
    }
  }
  coinValues:any
  coinBalances:any
  getBaseCoin(event) {
    this.buyBase = event.target.value
    this.getCoinDetails(this.buyBase)
  }


  selectFormValidation() {
    this.selectBuyForm = this.fb.group({
      'selectCoin': new FormControl('', [Validators.required]),

    })
  }

  buyFormValidation() {
    this.otcBuyForm = this.fb.group({
      'buyWithCoin': new FormControl('', [Validators.required]),
      'size': new FormControl('',),
      'amount': new FormControl('',),
    })
  }

  selectSellFormValidation() {
    this.selectSellForm = this.fb.group({
      'selectCoin': new FormControl('', [Validators.required]),

    })
  }

  sellFormValidation() {
    this.otcSellForm = this.fb.group({
      'sellWithCoin': new FormControl('', [Validators.required]),
      'size': new FormControl('',),
      'amount': new FormControl('',),
    })
  }

  coinOut: any
  amountOut: any
  totalOut: number = 0
  sellcoin: any
  sellflag = 0
  sellsize: number
  selectSellCoin: any
  sellQoute() {
    $("#acceptSell").removeAttr("disabled");
    this.coinOut = this.selectedCoin
    this.sellcoin = this.otcSellForm.value.sellWithCoin
    this.service.GetOTCData(this.sellcoin, this.coinOut).subscribe(response => {
      // console.log("Live data:", response)
      this.amountOut = response.AED || response.INR || response.BTC || response.USDT
    }, error => {
    })
    this.sellsize = this.otcSellForm.value.size || (this.otcSellForm.value.amount / this.amountOut)

    if (this.sellsize && this.sellcoin) {
      this.sellflag = 1
    }
    this.buyTimeInterval = setInterval(() => {
      this.getTimer();
    }, 2000);

    setTimeout(() => {
      this.totalOut = this.otcSellForm.value.size * this.amountOut || this.otcSellForm.value.amount
    }, 2000);


  }




  id1 = setInterval(() => {
    this.sellQoute();
  }, 7000);

  sell() {
    if (localStorage.getItem('credential')) {
      if (this.totalOut) {
        var data = {
          "baseCoin": this.selectedCoin,
          "baseCoinMarketPrice": this.amountOut,
          "baseCoinamount": this.sellsize,
          "exeCoin": this.otcSellForm.value.sellWithCoin,
          "exeCoinamount": Number(this.otcSellForm.value.amount || this.totalOut),
          "paymentMethod": "WALLET"
        }
        this.service.showSpinner();
        this.service.postCandyPixelForms('wallet/otc/sell-quote', data).subscribe(res => {
          this.service.hideSpinner();
          if (res['status'] == 200) {
            this.service.showSuccessMessage('Success')
            this.history()
            // this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
            this.otcSellForm.reset();
            this.rejectSell()
            // this.updateStatus('APPROVED') // open
          }
          if (res['status'] == 205) {
            this.service.showErrorMessage(res['message'])
          }
        }, err => {
          this.service.hideSpinner();
        })
      }
      else {
        return
      }
    }

    else {
      this.service.showErrorMessage('Please Login')
      return this.router.navigateByUrl('/login')
    }
  }

  coinIn: any
  amountIn: number
  totalIn: number = 0
  buysize: number
  flag = 0
  buyTimeInterval: any
  buyQoute() {
    this.coinIn = this.selectedCoin
    this.buyBaseCoin = this.buyBase
    this.service.GetOTCData(this.coinIn, this.buyBaseCoin).subscribe(response => {
      // console.log("Live data:", response)
      this.amountIn = response.AED || response.INR || response.BTC || response.USDT
      // if (response.Data) {
      //   response.Data.length ? this.newsData = response.Data : this.newsData = [];
      // }
    }, error => {
    })
    $("#acceptBuy").removeAttr("disabled");


    //console.log("size::", this.otcBuyForm.value.size)
    this.buysize = this.otcBuyForm.value.size || (this.otcBuyForm.value.amount / this.amountIn)

    if (this.buysize && this.buyBaseCoin) {
      this.flag = 1
    }
    this.buyTimeInterval = setInterval(() => {
      this.getTimer();
    }, 2000);

    setTimeout(() => {
      this.totalIn = this.otcBuyForm.value.size ? this.otcBuyForm.value.size * this.amountIn : this.otcBuyForm.value.amount
    }, 2000);

    //console.log("total:::", this.totalIn)


  }

  // this.battleInit();
  id = setInterval(() => {
    this.buyQoute();
  }, 7000);


  //timer
  countDownDate: any = new Date("Jan 5, 2022 15:37:25").getTime();

  timer: any
  // Update the count down every 1 second
  getTimer() {

    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = this.countDownDate - now;

    // Time calculations for days, hours, minutes and seconds

    var seconds = Math.floor((distance % (100 * 110)) / 1000);
    this.timer = seconds
    // Output the result in an element with id="demo"
    //document.getElementById("demo").innerHTML = seconds + "s ";

    // If the count down is over, write some text 
    // if (distance < 0) {
    //   clearInterval(x);
    //   document.getElementById("demo").innerHTML = "EXPIRED";
    // }
  }
  //timer time interval


  manageInput(value, type) {
    if (type == 'buy') {
      if (value == 'coin') {
        this.otcBuyForm.patchValue({
          'amount': ''
        })
      } else {
        this.otcBuyForm.patchValue({
          'size': ''
        })
      }
    } else {
      if (value == 'coin') {
        this.otcSellForm.patchValue({
          'amount': ''
        })
      } else {
        this.otcSellForm.patchValue({
          'size': ''
        })
      }
    }

  }

  buy() {
    if (localStorage.getItem('credential')) {
      var data = {
        "baseCoin": this.selectBuyForm.value.selectCoin,
        "baseCoinMarketPrice": this.amountIn,
        "baseCoinamount": this.buysize,
        "exeCoin": this.selectedCoin,
        "exeCoinamount": this.otcBuyForm.value.amount || this.totalIn,
        "paymentMethod": "WALLET"
      }
      this.service.showSpinner();
      this.service.postCandyPixelForms('wallet/otc/buy-quote', data).subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          this.service.showSuccessMessage('Quote requested successfully.')
          this.history()
          this.rejectBuy()
          // this.updateStatus('APPROVED')  // open 
        }
        else if (res['status'] == 205) {
          this.service.showErrorMessage(res['message'])
        }
        else if (res['status'] == 556) {
          this.service.showErrorMessage(res['message'])
        }
      }, err => {
        this.service.hideSpinner();
      })
    }
    else {
      this.service.showErrorMessage('Please Login')
      return this.router.navigateByUrl('/login')
    }

  }



  // options = {
  //   "key": "rzp_test_RPSOU2qRYVVquZ",
  //   "amount": this.totalIn * 100,
  //   "name": "Merchant Name",
  //   "currency": this.selectedCoin
  // };


  orderid: any
  total: any
  history() {
    var data = {
      "fkUserId": this.profileData.userId,
      "page": this.currPage -1,
      "pageSize": this.limit,

    }
    this.service.showSpinner();
    this.service.postCandyPixelForms('wallet/otc/search-and-filter-quote-request', data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.historyDetails = res['data']['resultlist']
        this.orderid = res['data']['resultlist'][0]['otcTradeDetailsId']
        this.total = res['data']['totalCount']
        // this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
        // this.otcBuyForm.reset();
      }
    }, err => {
      this.service.hideSpinner();
    })
  }

  rejectBuy() {
    $("#acceptBuy").attr('disabled', 'disabled');
    this.selectBuyForm.reset()
    this.otcBuyForm.reset()
    this.amountIn = 0
    this.totalIn = 0
    this.buysize = 0
    this.flag = 0
    clearInterval(this.id);
    this.coinIn = ''
    this.buyBaseCoin = ''


  }
  rejectSell() {
    $("#acceptSell").attr('disabled', 'disabled');
    this.otcSellForm.reset()
    this.selectSellForm.reset()
    this.amountOut = 0
    this.totalOut = 0
    this.availablebaln = 0
    this.sellflag = 0
    this.sellsize = 0
    clearInterval(this.id1);
    this.sellcoin = ''
    this.coinOut = ''


  }
  changeTab() {
    // if (this.id) {
    //   clearInterval(this.id);
    // }
    // if (this.id1) {
    //   clearInterval(this.id1);
    // }
    this.coinList = []
    this.getAllCoins()
    this.getAllcoinsBeforeLogin()
    this.availablebaln = 0;
    this.selectBuyForm.reset()
    this.otcBuyForm.reset()
    this.otcSellForm.reset()
    this.selectSellForm.reset()
    this.flag = 0
    this.sellflag = 0
    this.sellcoin = ''
    this.buyBaseCoin = ''
    this.coinOut = ''
    // this.rejectBuy()
    // this.rejectSell()
  }

  userListPagination(event) {
    this.currPage = event;
    this.history()
  }


  updateStatus(status) {
    console.log(status)
    this.service.showSpinner()
    this.service.getCandyPixelForms(`wallet/otc/approve-reject-quote-request?otcTradeDetailsId=${this.orderid}&status=${status}`).subscribe((res: any) => {

      if (res.status == 200) {
        this.service.hideSpinner()
        // if (status == 'REJECTED') {
        //   this.refundMoney()
        // }

        this.service.showSuccessMessage(res.message)
        //$('#approve').modal('hide')
        //$('#reject').modal('hide')
      } else {
        this.service.hideSpinner()
        this.service.showErrorMessage(res.message)
        //$('#reject').modal('hide')
        //$('#approve').modal('hide')
      }
    }, (err) => {
      this.service.hideSpinner()
      this.service.showErrorMessage(err.message)
    })
  }
  coinDetailsArray:any
getCoinDetails(coinValue){
  let url = `wallet/coin/get-coin-details?coinName=${coinValue}`
  this.service.getCandyPixelForms(url).subscribe(res => {
    if (res['status'] == 200) {
      this.coinDetailsArray = res['data']
    }
  }, err => {
  })
}
}
