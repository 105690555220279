import { Component, OnInit } from '@angular/core';

declare var $: any
@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.css']
})
export class MarketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // this.sliderfunc();
  }

  sliderfunc(){
    $(".main_slider").slick({
      dots: false,
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
     autoplay:true
    });
   responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
      infinite: true,
      dots: true
    }
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2
    }
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1
    }
  }
  // You can unslick at a given breakpoint now by adding:
  // settings: "unslick"
  // instead of a settings object
]
  
  }
}
