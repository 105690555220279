import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit {
  isLoggedIn: string;
  carrierData:any
  constructor(public service: MainService) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('credential') ? localStorage.getItem('credential') : ''
    this.getCarrier()
  }
  getCarrier(){ 
    this.service.showSpinner()
    this.service.getCandyPixelForms('static/get-static-page-data?pageKey=How%20It%20Works').subscribe(res=>{
           if (res['status']==200){
             this.carrierData=res['data'].pageData;
             this.service.hideSpinner()
             this.service.showSuccessMessage(res['message'])
           }
           if (res['status']==201){
             this.service.hideSpinner()
            this.service.showErrorMessage('No Data Found')
          }
          else{
            this.service.hideSpinner()
            this.service.showErrorMessage(res['message'])
          }
    })
   }
}
