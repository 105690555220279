import { Component,IterableDiffers } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MainService } from 'src/app/main.service';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'BlocxAngular';
  currUrl: string;
  isLoggedIn: boolean = false;
  selected: any = '/signup';
  selectedemailv: boolean = false;
  userImage: any = 'assets/images/profail-img.png';
  userName: any;
  profileData: any = {};
  chatArr: any = [];

  totalNotification: any;
  iterableDiffer

  constructor(public routes: Router, public service: MainService, private iterableDiffers: IterableDiffers) {

    var self = this;
    // $(document).bind("mousedown", function (e) {
    //   if (e.button == 0) {
    //     self.checkSession();
    //   }
    // });

    /*********** Routing Managed Start Here ***************/
    routes.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        if (this.currUrl == `login`) {
          this.selected = '/login';
        } else if (this.currUrl == `signup`) {
          this.selected = '/signup';
        }
        if (localStorage.credential) {
          this.service.changeLoginSub('login')
          if ((this.currUrl == `login` || this.currUrl == `register` || this.currUrl.includes('email-verify') || this.currUrl.includes('reset') || this.currUrl == `register2` || this.currUrl == `forgot`)) {
            this.routes.navigate([``])
          }
        } else {
          if (!(this.currUrl == `login` || this.currUrl.includes('') || this.currUrl.includes('p2p-buy') || this.currUrl.includes('email-verify') || this.currUrl.includes('reset') ||
            this.currUrl == `terms` || this.currUrl == `aboutUs` || this.currUrl == `contactUs` || this.currUrl == `privacy`
            || this.currUrl == `signup` || this.currUrl == `signup-detail` || this.currUrl == `forgot` || this.currUrl == `faq` ||
            this.currUrl == `advance` || this.currUrl == `basic` || this.currUrl == `buy-sell` || this.currUrl == `marketInfo` ||
            this.currUrl == `support` || this.currUrl == 'advance-exchange' || this.currUrl == 'trade/:id' || this.currUrl == `wallet-transaction-status`)) {
            this.routes.navigate([``])
          }
          this.service.changeLoginSub('logout');
        }
      }
    })
    /*********** Routing Managed End Here ***************/

    // to show notification count
    this.service.getMessage().subscribe((res) => {
      if (res.text.message) {
        if ((res.text.message.includes('trade request from'))) {
          this.chatArr.push(res.text);
          // console.log(this.chatArr.length)
        }
      }
    })
    this.iterableDiffer = iterableDiffers.find([]).create(null); // for unseen notification count
  }
id:any
  ngOnInit() {
    window.scrollTo(0, 0);
    this.id = localStorage.getItem('OrderId')
    this.service.initSocket();
    this.service.initSocketChat(); //chat
    this.service.initSocketNotification(); // notification
    //this.checkSession();
    // this.service.changeLoginSub('login');
    this.service.loginObs.subscribe(response => {

      if ($(".mobileresposnsive").hasClass("menushowhide")) {
        $(".mobileresposnsive").removeClass("menushowhide");
      };

      $('main').click(function () {
        if ($(".mobileresposnsive").hasClass("menushowhide")) {
          $(".mobileresposnsive").removeClass("menushowhide");
        };
      });

      $('li.custom-space').click(function () {
        if ($(".sidenav").hasClass("onetwo")) {
          $(".sidenav").removeClass("onetwo");
        };
      });

      $('main').click(function () {
        $(".sidenav").addClass("onetwo");
      });

      if (response == 'login') {
        this.isLoggedIn = true;
        console.log('After Login', this.isLoggedIn)
        this.getProfile();
      }
      else {
        this.isLoggedIn = false;
        console.log('Before Login', this.isLoggedIn)

      }
    })
  }

  /** Function to get diffrence between timestamp */
  diff_minutes(dt2, dt1) {
    var diff = (dt2 - dt1) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }
  /** Function for check session */
  // checkSession() {
  //   if (localStorage.getItem('credential') != null && localStorage.getItem('session') != null) {
  //     let minDiff = this.diff_minutes(parseInt(localStorage.getItem('session')), new Date().getTime());
  //     localStorage.setItem('session', new Date().getTime().toString());
  //     if (minDiff >= 10) {
  //       this.sessionExpiredFunc();
  //     }
  //   } else {
  //     localStorage.setItem('session', new Date().getTime().toString());
  //   }
  // }

  // Logout Functionality
  logout() {
    $('#logout').modal({ backdrop: 'static' });
  }

  logoutFunc() {
    $('#logout').modal('hide');
    localStorage.removeItem('credential')
    localStorage.removeItem('profiledata')
    this.routes.navigate(['/login'])
  }
  sessionExpiredFunc() {
    this.service.showErrorMessage('Session Expired!')
    $('#logout').modal('hide');
    localStorage.removeItem('credential')
    localStorage.removeItem('profiledata')
    this.routes.navigate(['/login'])
  }

  // <script>
  openNav() {
    // console.log('click open')
    document.getElementById("mySidenav").style.width = "250px";

  }

  closeNav() {
    // console.log('click close')
    document.getElementById("mySidenav").style.width = "0";
  }
  // </script>
  selectTab(tab) {
    this.selected = tab
  }


  navigateToBy(path) {
    this.routes.navigateByUrl(path);
  }

  navigateTo(path) {
    const token = localStorage.getItem('credential')
    if (token != null) {
      this.isLoggedIn = true
      this.routes.navigate(['/basic-buy-sell/sell'])
    }
    else {
      this.isLoggedIn = false
      this.routes.navigate(['/login'])
    }
    //this.routes.navigateByUrl(path)
  }

  // Get Profile Name-Email
  getProfile() {
    if (localStorage.getItem("credential")) {
      this.service.showSpinner();
      this.service.getCandyPixelForms('account/my-account').subscribe(res => {
        this.service.hideSpinner();
        if (res['status'] == 200) {
          var profileData = res['data'];
          this.profileData = res['data']
          localStorage.setItem('profiledata', JSON.stringify(this.profileData))
          this.userImage = profileData.imageUrl != null ? profileData.imageUrl : 'assets/images/profail-img.png';
          this.userName = profileData.firstName
        }
        else {
          this.service.showErrorMessage(res['message']);
        }
      }, err => {
        this.service.hideSpinner();
        // this.service.showError(err['message']);
      })
    }
  }


  security(event) {
    console.log("security option click", event);
    if (event == 'wallet') {
      this.routes.navigate(['/my-wallet'])
    }
    else if (event == 'withdraw') {
      this.routes.navigate(['/withdraw-history'])
    }
    else if (event == 'deposit') {
      this.routes.navigate(['/deposit-history'])
    }
  }

  new(event) {
    console.log("new option click", event);
    if (event == 'profile') {
      this.routes.navigate(['/my-profile'])
    }
    else if (event == 'login') {
      this.routes.navigate(['/login-history'])
    }
    else if (event == 'logout') {
      // this.routes.navigate(['/login'])
      // $('#myModal').modal('show')
      $('#logout').modal({ backdrop: 'static' });
    }
  }

  // get notification
  notifications() {
    console.log("notification fun call")
    this.routes.navigate(['/notifications'])
  }

  // get unseen notification count
  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.service.notificationArr);
    if (changes) {
      console.log('Changes detected!');
      // this.totalNotification = this.service.notificationArr.length
      let unSeenNotifCount = this.service.notificationArr.filter(x => x.isSeen == false)
      this.totalNotification = unSeenNotifCount.length
    }
  }

  openSocialLink(val){
    if (val=='facebook'){
      window.open('https://www.facebook.com/')
    }
    else if (val=='twitter'){
      window.open('https://twitter.com/?lang=en')
    }
    else if (val=='linkedin'){
      window.open('https://in.linkedin.com/')
    }
    else if (val=='googleplus'){
      window.open('https://myaccount.google.com/')
    }
    else if (val=='instagram'){
      window.open('https://www.instagram.com/')
    }
  }
}


