import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main.service';
import { FormGroup, FormControl, Validators,FormBuilder } from '@angular/forms';
import { Router, Event, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr'
declare var $: any;
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  isLoggedIn:any;
  supportForm: FormGroup;
  fileData: any;
  imageUrl: any;
  constructor(public router: Router, public service: MainService, private toaster: ToastrService, private fb: FormBuilder) { }

  ngOnInit() {
    window.scrollTo(0,0)
    this.isLoggedIn = localStorage.getItem('credential') ? localStorage.getItem('credential') : ''
    this.formValidation()
  }

  formValidation(){
    this.supportForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      'name': new FormControl('',[Validators.required,Validators.pattern(/^[a-z A-Z]*$/i)]),
      'description': new FormControl('',[Validators.required])
    })
  }

   // Contact Us Functionality
supportFunc(){
  let data={
    'name': this.supportForm.value.name,
    'email': this.supportForm.value.email,
    'description': this.supportForm.value.description,
    "category": "string",
    "imageUrl": "string",
    "subject": "string"
   
  }
this.service.showSpinner();
this.service.postCandyPixelForms('static/submit-support-ticket',data).subscribe(res=>{
  this.service.hideSpinner();
  if (res['status']==200){
    this.service.showSuccessMessage('Successfully submitted,we will get back to you shortly!')
    this.supportForm.reset();
  }
}, err=>{
  this.service.hideSpinner();
})
}

  sendConfirmation(){
    $('#supportmodal').modal({ backdrop: 'static' });
  }
}
